import {useFormik} from 'formik'
import {isArray, isEmpty} from 'lodash'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import {KTSVG, toAbsoluteUrl} from '../../../../../../_gori/helpers'
import {useStatesGlobal} from '../../../../../../_gori/helpers/components/StatesGlobalProvider'
import useCancelToken from '../../../../../../_gori/hooks/UseCancelToken'
import {Button, SelectFormik, ValidationErrorModal} from '../../../../../../_gori/partials/widgets'
import {SettingsConfig} from '../../../core/_const'
import SettingsService from '../../../core/_requests'

type Props = {
  show: boolean
  handleClose: () => void
  reloadTable: any
  dataPresetDefault?: any
}

const DefaultPresetModal: React.FC<Props> = ({
  show,
  handleClose,
  dataPresetDefault,
  reloadTable,
}) => {
  const intl = useIntl()
  const [isLoadingForm, setIsLoadingForm] = useState<boolean>(false)
  const {newCancelToken, isCancel} = useCancelToken()
  const [validationErrors, setValidationErrors] = useState<any>()
  const {statesGlobal, getPresets} = useStatesGlobal()
  const presets = statesGlobal.presets
  const [integrationStore, setIntegrationStore] = useState<any>([])
  const [storeType, setStoreType] = useState('')

  const handleCloseModal = () => {
    handleClose()
  }

  useEffect(() => {
    getPresets()

    return () => {}
  }, [getPresets])

  const handleChangeShop = async (e) => {
    await getIntegrationStores(e.value)
    setStoreType(e.value)
  }

  const getIntegrationStores = useCallback(
    async (type) => {
      try {
        const response = await SettingsService.connections({
          params: {
            store_type: type,
            status: 'all',
          },
          cancelToken: newCancelToken(),
        })
        setIntegrationStore(response)
      } catch (error) {
        if (isCancel(error)) return
      }
    },
    [isCancel, newCancelToken]
  )

  const optionsStoreType = useMemo(() => {
    return Object.entries(SettingsConfig.DEFAULT_PRESETS).map(([key, value]) => {
      let option: any = {}
      option.value = value.value
      option.label = (
        <>
          <img
            className='w-15px h-15px rounded-1 me-2'
            src={toAbsoluteUrl(`/media/gori/settings/${value.value}.png`)}
            alt='metronic'
          />
          {intl.formatMessage({id: value.label})}
        </>
      )
      option.flag = toAbsoluteUrl(`/media/gori/settings/${value.value}.png`)
      return option
    })
  }, [intl])

  const optionsPresets = useMemo(() => {
    if (isEmpty(presets)) return []
    return presets.map((preset) => {
      let option: any = {}
      option.label = preset.name
      option.value = preset.id
      return option
    })
  }, [presets])

  const initValue = useMemo(() => {
    const result = {
      default_presets: {},
    }
    dataPresetDefault.forEach((item) => {
      if (!result.default_presets[item.order_type]) {
        result.default_presets[item.order_type] = {}
      }
      result.default_presets[item.order_type][item.store_id] = item.preset_id
    })
    return result
  }, [dataPresetDefault])

  const formik = useFormik({
    initialValues: initValue,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const cleanArray = (array) => {
        if (!isArray(array)) {
          return array
        }
        const cleanedArray = {}
        array.forEach((value, index) => {
          if (value != null) {
            cleanedArray[index] = value
          }
        })
        return cleanedArray
      }
      const cleanData = (data) => {
        const cleanedPresets = {}
        for (const [presetName, presetData] of Object.entries(data.default_presets)) {
          cleanedPresets[presetName] = cleanArray(presetData)
        }
        return {
          ...data,
          default_presets: cleanedPresets,
        }
      }
      values = cleanData(values)

      const payload: any = []
      for (let orderType in values.default_presets) {
        for (let storeId in values.default_presets[orderType]) {
          payload.push({
            order_type: orderType,
            default_presets: [
              {
                preset_id: values.default_presets[orderType][storeId],
                store_id: storeId === 'null' ? null : storeId,
              },
            ],
          })
        }
      }

      try {
        setIsLoadingForm(true)
        const res = await SettingsService.setDefaultPreset(payload, {
          cancelToken: newCancelToken(),
        })
        if (res) {
          toast.success(intl.formatMessage({id: 'UPDATED_SUCCESSFULLY'}))
          handleCloseModal()
          reloadTable()
        }
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setIsLoadingForm(false)
      }
    },
  })

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      <Modal
        id='gori_modal_cancel_order'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='mw-800px h-auto'
        show={show}
        backdrop='static'
        onHide={() => {
          handleClose()
        }}
      >
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>
              {intl.formatMessage({id: 'DEFAULT_PRESETS'})}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='mx-10 my-5 d-flex flex-column justify-content-center fs-4'>
              <div className='col-12 fs-6 align-items-center d-block'>
                <div className='form-check-label form-label text-gray-600 text-uppercase'>
                  {intl.formatMessage({id: 'MARKETPLACE'})}
                </div>

                <div className='col-12 my-5'>
                  <SelectFormik
                    className='col-12'
                    placeholder={intl.formatMessage({id: 'CHOOSE_MARKETPLACE'})}
                    labelClassName='col-3 col-form-label'
                    options={optionsStoreType}
                    formik={formik}
                    name={'store_type'}
                    checkFormik={false}
                    emptyDefault={false}
                    onChange={(e) => handleChangeShop(e)}
                  />
                </div>

                {(storeType === SettingsConfig.DEFAULT_PRESETS.SHIPBAE.value ||
                  integrationStore?.connections?.length > 0) && (
                  <div className='col-12 d-flex my-5'>
                    <div className='col-4'>
                      <div className='col-9 text-gray-600 fw-bold text-uppercase'>
                        {intl.formatMessage({id: 'STORE_NAME'})}
                      </div>
                    </div>
                    <div className='col-8'>
                      <div className='col-9 text-gray-600 fw-bold text-uppercase'>
                        {intl.formatMessage({id: 'DEFAULT_PRESET'})}
                      </div>
                    </div>
                  </div>
                )}

                {storeType === SettingsConfig.DEFAULT_PRESETS.SHIPBAE.value && (
                  <div className='col-12 d-flex my-5'>
                    <div className='col-4'>
                      <div className='col-form-label'>{intl.formatMessage({id: 'SHIPBAE'})}</div>
                    </div>
                    <div className='col-8'>
                      <SelectFormik
                        className='col-12'
                        placeholder={intl.formatMessage({id: 'CHOOSE_PRESET'})}
                        labelClassName='col-form-label'
                        options={optionsPresets}
                        formik={formik}
                        name={`default_presets.${storeType}.null`}
                        isClearValue
                        checkFormik={false}
                        emptyDefault={false}
                      />
                    </div>
                  </div>
                )}

                {integrationStore?.connections &&
                  integrationStore.connections.map((store: any, index) => {
                    return (
                      <div className='col-12 d-flex my-5' key={index}>
                        <div className='col-4'>
                          <div className='col-form-label'>{store?.store_name}</div>
                        </div>
                        <div className='col-8'>
                          <SelectFormik
                            className='col-12'
                            placeholder={intl.formatMessage({id: 'CHOOSE_PRESET'})}
                            labelClassName='col-form-label'
                            options={optionsPresets}
                            formik={formik}
                            name={`default_presets.${store.store_type}.${store.id}`}
                            isClearValue
                            checkFormik={false}
                            emptyDefault={false}
                          />
                        </div>
                      </div>
                    )
                  })}
              </div>

              <div className='fs-6 d-flex align-items-center my-5'>
                <KTSVG
                  path='/media/gori/settings/errorsPreset.svg'
                  className='text-danger svg-icon-3'
                />
                <span className='fs-6 ms-3'>
                  {intl.formatMessage({
                    id: 'THE_DEFAULT_PRESET_WILL_OVERWRITE_THE_SHIPPING_INFORMATION',
                  })}
                </span>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <Button
                className='btn btn-secondary me-2'
                label={intl.formatMessage({id: 'CANCEL'})}
                loadingText={intl.formatMessage({id: 'CANCEL'})}
                event={handleCloseModal}
                disabled={isLoadingForm}
              />
              <Button
                className='btn btn-primary'
                label={intl.formatMessage({id: 'SAVE'})}
                loadingText={intl.formatMessage({id: 'SAVE'})}
                event={formik.handleSubmit}
                loading={isLoadingForm}
              />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {DefaultPresetModal}

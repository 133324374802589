import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {Link, useNavigate} from 'react-router-dom'
import {CSSTransition} from 'react-transition-group'
import {KTSVG, StorageHelpers} from '../../../../_gori/helpers'
import useCancelToken from '../../../../_gori/hooks/UseCancelToken'
import {Button} from '../../../../_gori/partials/widgets'
import UserService from '../../users/core/_requests'
import {TUTORIAL_CARDS} from '../core/_const'
import {useAuth} from '../../auth'

const TutorialCards: React.FC = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const {loadingSwitch, currentUser} = useAuth()
  const {newCancelToken, isCancel} = useCancelToken()
  const [visibleTutorialCards, setVisibleTutorialCards] = useState(TUTORIAL_CARDS)
  const [tutorialCard, setTutorialCard] = useState(null)

  const getTutorialCard = useCallback(async () => {
    const settings = await UserService.getTutorialCard({cancelToken: newCancelToken()})
    setTutorialCard(settings)
  }, [newCancelToken])

  useEffect(() => {
    if (loadingSwitch) return

    getTutorialCard()
  }, [getTutorialCard])

  const handleRemoveTutorialCard = useCallback(
    async (tutorialCardKey) => {
      StorageHelpers.setItemLocalStorage('card' + tutorialCardKey, {
        hidden: true,
      })
      try {
        setVisibleTutorialCards((prevCards) =>
          prevCards.filter((card) => card.key !== tutorialCardKey)
        )
        await UserService.removeTutorialCard(
          {
            card_key: tutorialCardKey,
            is_show: false,
          },
          {cancelToken: newCancelToken()}
        )
      } catch (error: any) {
        if (isCancel(error)) return
      } finally {
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isCancel, newCancelToken]
  )

  const filteredTutorialCards = useMemo(() => {
    if (!tutorialCard) return []

    return visibleTutorialCards.filter((card) => {
      const isHiddenCardClosed = currentUser?.user_profiles?.tutorial_cards?.some(
        (userCardOnboard) => userCardOnboard.card_key === card.key && !userCardOnboard.is_show
      )
      const isHiddenBySetting = tutorialCard?.[card.key] === true

      const isHiddenInLocalStorage = StorageHelpers.getItemLocalStorage('card' + card.key)

      return !(isHiddenCardClosed || isHiddenBySetting || isHiddenInLocalStorage)
    })
  }, [visibleTutorialCards, currentUser, tutorialCard])

  const handleNavigate = (linkTo) => {
    navigate(linkTo)
  }

  return (
    <>
      <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
        <div className='d-flex mb-5'>
          {filteredTutorialCards.map((card, index) => (
            <div className='card me-5 mw-250px' key={index}>
              <div className='card-body ps-4 pe-3 py-5 d-flex flex-column'>
                <Button
                  className='text-hover-dark border-0 bg-transparent rounded-circle py-1 position-absolute end-0 me-4'
                  event={() => handleRemoveTutorialCard(card.key)}
                >
                  <KTSVG
                    path='/media/gori/orders/delete.svg'
                    className='opacity-50 m-0'
                    svgClassName='mh-20px'
                  />
                </Button>

                <h6
                  className='card-title mt-6 fs-7 text-capitalize'
                  onClick={() => handleNavigate(card.linkTo)}
                  role='button'
                >
                  {intl.formatMessage({id: card.titleId})}
                </h6>
                <p
                  className='card-text fs-8'
                  onClick={() => handleNavigate(card.linkTo)}
                  role='button'
                >
                  {intl.formatMessage({id: card.contentId})}
                </p>
                <Link
                  to={card.subLinkTo}
                  className='card-link fs-8 m-auto mb-0 ms-0 text-decoration-underline'
                  target='_blank'
                >
                  {intl.formatMessage({id: card.subLinkText})}
                  <KTSVG
                    path='/media/gori/orders/vector.svg'
                    className='opacity-50 ms-0'
                    svgClassName='mh-10px'
                  />
                </Link>
              </div>
            </div>
          ))}
        </div>
      </CSSTransition>
    </>
  )
}

export {TutorialCards}

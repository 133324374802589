import clsx from 'clsx'
import {FormikProps, getIn} from 'formik'
import {compact} from 'lodash'
import {FC, useEffect, useMemo} from 'react'

type Props = {
  onChange?: any
  className?: string
  labelClassName?: string | null
  inputClassName?: string | null
  label?: any | null
  size?: 'sm' | 'lg' | null
  type?: 'text' | 'number'
  placeholder?: string
  required?: boolean
  formik: FormikProps<any>
  name?: any
  bgSolid?: boolean
  min?: number
  max?: number
  isCurrency?: boolean
  isInteger?: boolean
  isPositive?: boolean
  isTextArea?: boolean
  checkFormik?: boolean
  disabled?: boolean
  onFocus?: any
  hidden?: boolean
  children?: any
  maxlength?: number
  configAppend?: {
    name: string
    position: 'right' | 'left'
    classInput?: string
    classAppend?: string
    hasEvent?: boolean
    handleSubmit?: any
  }
  formatDate?: boolean
}

const InputTextFormik: FC<Props> = ({
  onChange = undefined,
  className,
  labelClassName = '',
  inputClassName = '',
  label = null,
  size = '',
  type = 'text',
  placeholder = '',
  required = false,
  formik,
  name,
  bgSolid = false,
  min,
  max,
  isCurrency = false,
  isInteger,
  isTextArea = false,
  isPositive = true,
  checkFormik = true,
  disabled = false,
  onFocus,
  hidden = false,
  children,
  maxlength,
  configAppend,
  formatDate = false,
}) => {
  let fieldProps = formik.getFieldProps(name)
  let formikErrors = getIn(formik.errors, name)
  let formikTouched = getIn(formik.touched, name)

  const checkValidate = useMemo(() => {
    const isInvalid = formikTouched && formikErrors && checkFormik
    const isValid = formikTouched && !formikErrors && fieldProps.value && checkFormik
    return {isInvalid, isValid}
  }, [checkFormik, fieldProps.value, formikErrors, formikTouched])

  const handleClickAppend = () => {
    if (!configAppend?.hasEvent && checkValidate.isInvalid) return
    if (!formikErrors && fieldProps.value && configAppend?.handleSubmit) {
      configAppend?.handleSubmit()
    }
  }

  const handleFormatDate = (value, event?: any) => {
    let numericValue = value.trim().replace(/\D/g, '')
    if (numericValue.length >= 2) {
      numericValue = numericValue.slice(0, 2) + '/' + numericValue.slice(2, 4)
    }
    if (event?.nativeEvent?.inputType === 'deleteContentBackward' && value.endsWith('/')) {
      numericValue = numericValue.slice(0, -1)
    }
    formik.handleChange({target: {name: fieldProps.name, value: numericValue}})
  }

  const setTextAreaHeight = (el) => {
    el.style.height = '5px'
    el.style.height = el.scrollHeight + 'px'
  }

  useEffect(() => {
    const allTA = document.querySelectorAll('textarea')
    allTA.forEach((el) => {
      setTextAreaHeight(el)
    })
  }, [])

  return (
    <>
      {label && (
        <label className={`form-label ${labelClassName} ${required ? 'required' : ''}`}>
          {label}
        </label>
      )}
      {isTextArea && (
        <div className={className}>
          <div className='input-custom'>
            <textarea
              disabled={disabled}
              autoComplete='off'
              className={clsx('form-control input-custom__input overflow-hidden', {
                [inputClassName ?? '']: !!inputClassName,
                [`form-control-${size}`]: size,
                'form-control-solid': bgSolid,
                'cursor-no-drop': disabled,
                'is-invalid': checkValidate.isInvalid,
                'is-valid': checkValidate.isValid,
                [configAppend?.classInput ?? '']: !!configAppend?.classInput,
              })}
              {...fieldProps}
              maxLength={maxlength}
              rows={1}
              placeholder={placeholder}
              onKeyPress={(event) => {
                if (/[-+]/.test(event.key) && isPositive && type === 'number') {
                  event.preventDefault()
                }
                if (!/[0-9]/.test(event.key) && isInteger) {
                  event.preventDefault()
                }
                if (/[,]/.test(event.key) && isCurrency) {
                  event.preventDefault()
                }
              }}
              onFocus={(e) => {
                if (e.target.value === '0') {
                  let eventField = {target: {name: fieldProps.name, value: ''}}
                  formik.handleChange(eventField)
                }
                onFocus && onFocus()
              }}
              hidden={hidden}
              onChange={(event: any) => {
                if (/[-+]/.test(event.target.value) && isPositive && type === 'number') {
                  event.preventDefault()
                }
                if (!/[0-9]/.test(event.target.value) && isInteger) {
                  event.preventDefault()
                }
                if (/[,]/.test(event.target.value) && isCurrency) {
                  event.preventDefault()
                }

                let eventField = {target: {name: fieldProps.name, value: event.target.value}}
                formik.handleChange(eventField)
                if (onChange) {
                  onChange(event)
                }
                if (formatDate) {
                  handleFormatDate(event.target.value, event)
                }

                setTextAreaHeight(event.target)
              }}
              onPaste={(event: any) => {
                event.preventDefault()
                setTimeout(() => {
                  setTextAreaHeight(event.target)
                }, 50)

                const currentValue = getIn(formik.values, fieldProps.name)
                const pastedData = event.clipboardData.getData('text/plain')

                const selectionStart = event.target.selectionStart
                const selectionEnd = event.target.selectionEnd

                let newValue
                switch (type) {
                  case 'text':
                    newValue = compact([
                      currentValue?.slice(0, selectionStart),
                      pastedData,
                      currentValue?.slice(selectionEnd),
                    ]).join('')
                    break
                  case 'number':
                    newValue = compact([currentValue, pastedData]).join('')
                    break
                }

                if (isPositive && type === 'number') {
                  newValue = newValue.replace(/[-+]/g, '')
                }
                if (isInteger) {
                  newValue = newValue.replace(/[^0-9]/g, '')
                }
                if (isCurrency) {
                  newValue = newValue.replace(/,/g, '')
                }
                if (formatDate) {
                  handleFormatDate(newValue)
                }
                if (maxlength) {
                  newValue = newValue.slice(0, maxlength)
                }

                formik.setFieldValue(fieldProps.name, newValue)

                if (type === 'text') {
                  setTimeout(() => {
                    const inputElement = event.target
                    const newCursorPosition = selectionStart + pastedData.length
                    inputElement.setSelectionRange(newCursorPosition, newCursorPosition)
                  }, 0)
                }
              }}
              onInput={(event: any) => {
                setTextAreaHeight(event.target)
              }}
              style={{resize: 'none', minHeight: '100px'}}
            />
            <span
              className={clsx(
                `input-custom__append input-custom__append--${configAppend?.position}`,
                {
                  'cursor-pointer text-hover-primary':
                    configAppend?.hasEvent && checkValidate.isValid,
                  'cursor-no-drop': configAppend?.hasEvent && checkValidate.isInvalid,
                  [configAppend?.classAppend ?? '']: !!configAppend?.classAppend,
                }
              )}
              onClick={handleClickAppend}
            >
              {configAppend?.name}
            </span>
          </div>
          {children}
          {formikTouched && formikErrors && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block text-danger'>
                <span role='alert'>{formikErrors}</span>
              </div>
            </div>
          )}
        </div>
      )}
      {!isTextArea && (
        <div className={className}>
          <div className='input-custom'>
            <input
              disabled={disabled}
              type={type}
              autoComplete='off'
              className={clsx('form-control input-custom__input', {
                [inputClassName ?? '']: !!inputClassName,
                [`form-control-${size}`]: size,
                'form-control-solid': bgSolid,
                'cursor-no-drop': disabled,
                'is-invalid': checkValidate.isInvalid,
                'is-valid': checkValidate.isValid,
                [configAppend?.classInput ?? '']: !!configAppend?.classInput,
              })}
              {...fieldProps}
              maxLength={maxlength}
              placeholder={placeholder}
              min={min}
              max={max}
              onKeyPress={(event) => {
                if (/[-+]/.test(event.key) && isPositive && type === 'number') {
                  event.preventDefault()
                }
                if (!/[0-9]/.test(event.key) && isInteger) {
                  event.preventDefault()
                }
                if (/[,]/.test(event.key) && isCurrency) {
                  event.preventDefault()
                }
              }}
              onFocus={(e) => {
                if (+e.target.value === 0) {
                  let eventField = {target: {name: fieldProps.name, value: ''}}
                  formik.handleChange(eventField)
                }
                onFocus && onFocus()
              }}
              hidden={hidden}
              onChange={(event: any) => {
                if (/[-+]/.test(event.target.value) && isPositive && type === 'number') {
                  event.preventDefault()
                }
                if (!/[0-9]/.test(event.target.value) && isInteger) {
                  event.preventDefault()
                }
                if (/[,]/.test(event.target.value) && isCurrency) {
                  event.preventDefault()
                }

                let eventField = {target: {name: fieldProps.name, value: event.target.value}}
                formik.handleChange(eventField)
                if (onChange) {
                  onChange(event)
                }
                if (formatDate) {
                  handleFormatDate(event.target.value, event)
                }
              }}
              onPaste={(event: any) => {
                event.preventDefault()

                const currentValue = getIn(formik.values, fieldProps.name)
                const pastedData = event.clipboardData.getData('text/plain')

                const selectionStart = event.target.selectionStart
                const selectionEnd = event.target.selectionEnd

                let newValue
                switch (type) {
                  case 'text':
                    newValue = compact([
                      currentValue?.slice(0, selectionStart),
                      pastedData,
                      currentValue?.slice(selectionEnd),
                    ]).join('')
                    break
                  case 'number':
                    newValue = compact([currentValue, pastedData]).join('')
                    break
                }

                if (isPositive && type === 'number') {
                  newValue = newValue.replace(/[-+]/g, '')
                }
                if (isInteger) {
                  newValue = newValue.replace(/[^0-9]/g, '')
                }
                if (isCurrency) {
                  newValue = newValue.replace(/,/g, '')
                }
                if (formatDate) {
                  handleFormatDate(newValue)
                }
                if (maxlength) {
                  newValue = newValue.slice(0, maxlength)
                }

                formik.setFieldValue(fieldProps.name, newValue)

                if (type === 'text') {
                  setTimeout(() => {
                    const inputElement = event.target
                    const newCursorPosition = selectionStart + pastedData.length
                    inputElement.setSelectionRange(newCursorPosition, newCursorPosition)
                  }, 0)
                }
              }}
            />
            <span
              className={clsx(
                `input-custom__append input-custom__append--${configAppend?.position}`,
                {
                  'cursor-pointer text-hover-primary':
                    configAppend?.hasEvent && checkValidate.isValid,
                  'cursor-no-drop': configAppend?.hasEvent && checkValidate.isInvalid,
                  [configAppend?.classAppend ?? '']: !!configAppend?.classAppend,
                }
              )}
              onClick={handleClickAppend}
            >
              {configAppend?.name}
            </span>
          </div>
          {children}
          {formikTouched && formikErrors && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block text-danger'>
                <span role='alert'>{formikErrors}</span>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export {InputTextFormik}
